exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktualnosci-index-jsx": () => import("./../../../src/pages/aktualnosci/index.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-index-jsx" */),
  "component---src-pages-aktualnosci-prismic-news-and-events-uid-jsx": () => import("./../../../src/pages/aktualnosci/{PrismicNewsAndEvents.uid}.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-prismic-news-and-events-uid-jsx" */),
  "component---src-pages-deklaracja-dostepnosci-jsx": () => import("./../../../src/pages/deklaracja-dostepnosci.jsx" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-jsx" */),
  "component---src-pages-historia-jsx": () => import("./../../../src/pages/historia.jsx" /* webpackChunkName: "component---src-pages-historia-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kadra-jsx": () => import("./../../../src/pages/kadra.jsx" /* webpackChunkName: "component---src-pages-kadra-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-naszedomy-jsx": () => import("./../../../src/pages/naszedomy.jsx" /* webpackChunkName: "component---src-pages-naszedomy-jsx" */),
  "component---src-pages-onas-jsx": () => import("./../../../src/pages/onas.jsx" /* webpackChunkName: "component---src-pages-onas-jsx" */)
}

